import { Navigate, type RouteObject } from 'react-router-dom'
import { ErrorBoundary } from '@trinity/components'

export const styleflowRoutes: RouteObject = {
  path: 'styleflow',
  errorElement: <ErrorBoundary />,
  children: [
    { index: true, element: <Navigate replace to='collections' /> },
    {
      path: 'collections',
      children: [
        {
          index: true,
          async lazy() {
            const { Collections, loader, action } = await import('./collections')
            return { Component: Collections, loader, action }
          },
        },
        {
          path: ':collectionId',
          id: 'styleflowCollection',
          async lazy() {
            const { Collection, loader } = await import('./collections.$id')
            return { Component: Collection, loader }
          },
          children: [
            { index: true, element: <Navigate replace to='swatches' /> },
            {
              index: true,
              path: 'swatches',
              async lazy() {
                const { CollectionSwatches } = await import('./collections.$id.swatches')
                return { Component: CollectionSwatches }
              },
            },
            {
              path: 'garments',
              async lazy() {
                const { CollectionGarments, loader } = await import('./collections.$id.garments')
                return { Component: CollectionGarments, loader }
              },
            },
          ],
        },
      ],
    },
    {
      path: 'fabrics/:fabricId',
      async lazy() {
        const { Fabric } = await import('./fabrics.$id')
        return { Component: Fabric }
      },
      children: [
        {
          index: true,
          async lazy() {
            const { FabricsIndex } = await import('./fabrics.$id.index')
            return { Component: FabricsIndex }
          },
        },
        {
          path: 'garmentType',
          async lazy() {
            const { GarmentType } = await import('./fabrics.$id.garmentType')
            return { Component: GarmentType }
          },
        },
        {
          path: 'button',
          async lazy() {
            const { Button } = await import('./fabrics.$id.button')
            return { Component: Button }
          },
        },
      ],
    },
  ],
}
